import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/services-hero.jpg';
import TwoCol from '../components/rows/twocol/index';
import Follow from '../components/rows/cta/follow';
import Cta from '../components/rows/cta/index';

export default function Services({ data }) {
  let service = [];
  let service2 = [];
  {
    data.allStrapiCareers.edges.map(
      ({ node }) => (
        node.service.forEach((e) => service.push(e)),
        node.service2.forEach((e) => service2.push(e))
      )
    );
  }

  return (
    <Layout>
      <Seo
        title="Our Services"
        description="Find out more about our PACE Cardiology services. From cardiac care, diagnostics, research & more, you’ll 
          get impressive care when you visit PACE."
      />
      <Hero title="Our Services" banner={Banner} />
      <div className="service-sections">
        {service.map((s, i) => {
          const reverse = !!(i % 2 !== 0);
          return (
            <TwoCol
              key={s.id}
              reverse={reverse}
              ghost
              title={s.title}
              description={s.description}
              link={s.button_title}
              href={s.button_link}
              image={s.image?.localFile?.publicURL}
            />
          );
        })}
      </div>
      <Cta />
      <div className="service-sections">
        {service2.map((s, i) => {
          const reverse = !!(i % 2 !== 0);

          return (
            <TwoCol
              key={s.id}
              ghost
              reverse={reverse}
              title={s.title}
              description={s.description}
              link={s.button_title}
              href={s.button_link}
              image={s.image?.localFile?.publicURL}
            />
          );
        })}
      </div>
      <div className="mt-52" />
      <Follow />
    </Layout>
  );
}

export const query = graphql`
  query MyQuery {
    allStrapiCareers {
      edges {
        node {
          id
          service {
            id
            title
            description
            button_title
            button_link
            image {
              localFile {
                publicURL
              }
            }
          }
          service2 {
            id
            title
            description
            button_title
            button_link
            image {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
